.section-container {
    background: linear-gradient(to right, var(--hero-background-left), var(--hero-background-right));
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    max-width: 1600px;
}

/* Text section */
.text-section {
    width: 60%;
    padding: 0 32px;
}

.text-headline {
    visibility: hidden;
    margin-bottom: 12px;
    color: var(--hero-headline-color);
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
    align-items: start;
    justify-content: start;
    display: flex;
    animation: slideInFromTop 0.8s ease-out forwards;
}

.text-title {
    visibility: hidden;
    width: 500px;
    color: var(--hero-title-color);
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    text-align: start;
    animation: slideInFromTop 0.8s ease-out forwards 0.4s;
}

.text-descritpion {
    visibility: hidden;
    width: 475px;
    margin: 32px 0;
    color: var(--hero-description-color);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .7px;
    line-height: 1.6rem;
    text-align: start;
    animation: slideInFromTop 0.8s ease-out forwards 0.8s;
}

.text-start-btn {
    visibility: hidden;
    padding: 14px 20px;
    color: var(--hero-start-button-text-color);
    border: 1px solid var(--hero-start-button-border);
    border-radius: 28px;
    outline: var(--hero-start-button-outline);
    background-color: var(--hero-start-button-background);
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    align-items: start;
    justify-content: start;
    display: flex;
    animation: slideInFromTop 0.8s ease-out forwards 1.2s;
}

.icon-right-margin {
    margin-right: 8px;
}

.text-start-btn:hover {
    color: var(--hero-start-button-hover-text-color);
    background-color: var(--hero-start-button-hover-background);
    border: 1px solid var(--hero-start-button-hover-text-color);
}

.text-stats {
    visibility: hidden;
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    animation: slideInFromTop 0.8s ease-out forwards 1.4s;
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
        visibility: visible;
    }

    to {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

.text-stats-container {
    text-align: center;
}

.text-stats-container>p {
    color: var(--hero-stats-color);
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
}

.text-stats-container p:first-child {
    margin-bottom: 8px;
    color: var(--hero-stats-first-color);
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-weight: bold;
}

/* Image section */
.hero-image-section {
    width: 40%;
    max-width: 100%;
    text-align: center;
    animation: slideInFromRight 2s ease-out forwards;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(25%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.hero-image1 {
    width: 100%;
    height: auto;
}

/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--hero-scroll-button-text-color);
    border: 3px solid var(--hero-scroll-button-border);
    border-radius: 50%;
    background-color: var(--hero-scroll-button-background);
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}

.show-scroll {
    display: flex;
}

/* Responsive */
@media screen and (max-width: 900px) {
    .hero-image-section {
        display: none;
    }

    .text-section {
        width: 100%;
        padding: 0;
    }

    .text-title,
    .text-descritpion {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .text-headline {
        font-size: 20px;
    }

    .text-title {
        font-size: 28px;
    }

    .text-descritpion {
        font-size: 16px;
    }

    .text-appointment-btn {
        font-size: 16px;
    }

    .text-stats {
        gap: 18px;
    }

    .text-stats-container>p {
        font-size: 16px;
    }

    .text-stats-container p:first-child {
        font-size: 22px;
    }

    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}