.flow-environment {
    background: linear-gradient(to bottom right, var(--flow-background-left), var(--flow-background-right));
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flow-container {
    max-width: 1400px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.flow {
    position: relative;
    width: 100%;
}

.flow-title {
    margin: 30px 0px 50px 0px;
    color: var(--flow-title-color);
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.flow-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flow-item {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
}

.flow-item.left {
    margin-right: 20px;
    align-items: right;
    justify-self: right;
    justify-content: right;
}

.flow-item.right {
    margin-left: 20px;
    align-items: left;
    justify-self: left;
    justify-content: left;
}

.flow-content {
    text-align: start;
    overflow-wrap: break-word;
    max-width: 70%;
}

.flow-content-title {
    text-align: start;
    font-size: 36px;
    color: var(--flow-content-title-color);
}

.flow-content-text {
    margin-top: 10px;
    text-align: start;
    font-size: 24px;
    color: var(--flow-content-text-color);
    font-weight: 400;
}

.flow-graphic {
    background-color: var(--flow-graphic-background);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--flow-graphic-box-shadow-color);
    margin: 0 20px;
    max-width: 70%;
}

.flow-graphic img {
    max-width: 80%;
    height: auto;
}