:root {
    --background-color: #ffffff;
    --text-color: #000000;
    --header-background: #f8f9fa;
    --header-background-before-left: #f8f3ee;
    --header-background-before-right: #ede0d4;
    --header-title-color: #76553b;
    --header-start-button-background: #B08968;
    --header-start-button-text-color: #ffffff;
    --header-start-button-border: transparent;
    --header-start-button-outline: transparent;
    --header-start-button-hover-background: #e6ccb2;
    --header-start-button-hover-border: #B08968;
    --hero-background-left: #f8f3ee;
    --hero-background-right: #ede0d4;
    --hero-headline-color: #4d4b4b;
    --hero-title-color: #000000;
    --hero-description-color: #B08968;
    --hero-start-button-text-color: #ffffff;
    --hero-start-button-border: transparent;
    --hero-start-button-outline: transparent;
    --hero-start-button-background: #B08968;
    --hero-start-button-hover-background: #e6ccb2;
    --hero-start-button-hover-border: #B08968;
    --hero-start-button-hover-text-color: #B08968;
    --hero-stats-color: #4d4b4b;
    --hero-stats-first-color: #B08968;
    --hero-scroll-button-background: #b08968;
    --hero-scroll-button-text-color: #ffffff;
    --hero-scroll-button-border: #ffffff;
    --info-title-color: #434343;
    --info-subtitle-color: #bab8b8;
    --info-background: #ffffff;
    --info-title-before-background: #9D2553;
    --info-description-color: #4d4b4b;
    --info-cards-border: #d7d3d3;
    --info-cards-background: #ffffff;
    --info-cards-box-shadow-color: #0000000d;
    --info-icon-color: #B08968;
    --info-card-title-color: #4d4b4b;
    --info-card-description-color: #4d4b4b;
    --flow-background-left: #ffffff;
    --flow-background-right: #ede0d4;
    --flow-title-color: #434343;
    --flow-content-title-color: #76553b;
    --flow-content-text-color: #3e3e3e;
    --flow-graphic-background: #ffffff;
    --flow-graphic-box-shadow-color: #0000001a;
    --footer-color: #333333;
    --footer-background-left: #f3e9e0;
    --footer-background-right: #e7d8ca;
    --footer-overlay: #000000bf;
    --footer-overlay-content-background: #ffffff;
    --footer-overlay-content-box-shadow-color: #0000001a;
    --footer-overlay-header-background: #ffffff;
    --footer-overlay-close-button-color: #333333;
    --footer-overlay-close-button-hover-background: #d3d3d3;
    --footer-overlay-close-button-hover-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #121212;
        --text-color: #e0e0e0;
        --header-background: #1e1e1e;
        --header-background-before-left: #1e1e1e;
        --header-background-before-right: #2c2c2c;
        --header-title-color: #ffffff;
        --header-start-button-background: #3e3e3e;
        --header-start-button-text-color: #ffffff;
        --header-start-button-border: transparent;
        --header-start-button-outline: transparent;
        --header-start-button-hover-background: #555555;
        --header-start-button-hover-border: #3e3e3e;
        --hero-background-left: #1e1e1e;
        --hero-background-right: #2c2c2c;
        --hero-headline-color: #e0e0e0;
        --hero-title-color: #ffffff;
        --hero-description-color: #b08968;
        --hero-start-button-text-color: #ffffff;
        --hero-start-button-border: transparent;
        --hero-start-button-outline: transparent;
        --hero-start-button-background: #3e3e3e;
        --hero-start-button-hover-background: #555555;
        --hero-start-button-hover-border: #3e3e3e;
        --hero-start-button-hover-text-color: #b08968;
        --hero-stats-color: #e0e0e0;
        --hero-stats-first-color: #b08968;
        --hero-scroll-button-background: #3e3e3e;
        --hero-scroll-button-text-color: #ffffff;
        --hero-scroll-button-border: #ffffff;
        --info-title-color: #e0e0e0;
        --info-subtitle-color: #bab8b8;
        --info-background: #1e1e1e;
        --info-title-before-background: #9D2553;
        --info-description-color: #e0e0e0;
        --info-cards-border: #555555;
        --info-cards-background: #2c2c2c;
        --info-cards-box-shadow-color: #0000000d;
        --info-icon-color: #b08968;
        --info-card-title-color: #e0e0e0;
        --info-card-description-color: #e0e0e0;
        --flow-background-left: #1e1e1e;
        --flow-background-right: #2c2c2c;
        --flow-title-color: #e0e0e0;
        --flow-content-title-color: #ffffff;
        --flow-content-text-color: #b0b0b0;
        --flow-graphic-background: #2c2c2c;
        --flow-graphic-box-shadow-color: #0000001a;
        --footer-color: #cccccc;
        --footer-background-left: #2c2c2c;
        --footer-background-right: #3e3e3e;
        --footer-overlay: #000000bf;
        --footer-overlay-content-background: #1e1e1e;
        --footer-overlay-content-box-shadow-color: #0000001a;
        --footer-overlay-header-background: #1e1e1e;
        --footer-overlay-close-button-color: #cccccc;
        --footer-overlay-close-button-hover-background: #555555;
        --footer-overlay-close-button-hover-color: #ffffff;
    }
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
}