.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: var(--header-background);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: opacity 0.5s ease;
    overflow: hidden;
}

.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, var(--header-background-before-left), var(--header-background-before-right));
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: -1;
}

.header-scrolled::before {
    opacity: 0;
}

.header-scrolled {
    background: none;
    background-color: var(--header-background);
    opacity: 0.95;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 6px 20px rgba(0, 0, 0, 0.05);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}

.header-title {
    font-size: 40px;
    color: var(--header-title-color);
}

.start-button {
    margin: 10px 0px;
    padding: 14px 20px;
    color: var(--header-start-button-text-color);
    border: 1px solid var(--header-start-button-border);
    border-radius: 28px;
    outline: var(--header-start-button-outline);
    background-color: var(--header-start-button-background);
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    transition: all .4s ease;
    align-items: start;
    justify-content: start;
    display: flex;
    opacity: 0;
    transition: 0.5s ease;
}

.icon-right-margin {
    margin-right: 8px;
}

.button-scrolled {
    opacity: 1;
    cursor: pointer;
}

.start-button:hover {
    color: var(--header-start-button-background);
    background-color: var(--header-start-button-hover-background);
    border: 1px solid var(--header-start-button-hover-border);
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        padding: 10px;
    }

    .logo {
        margin-bottom: 10px;
    }

    .start-button {
        padding: 5px 10px;
    }
}