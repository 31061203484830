.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.info-title {
    margin: 100px 0px 15px 0px;
    color: var(--info-title-color);
    font-size: 40px;
}

.info-subtitle {
    color: var(--info-subtitle-color);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.info-section {
    padding: 0 32px;
    text-align: center;
    background-color: var(--info-background);
}

.info-title-content {
    margin: 64px 0 128px 0;
    max-width: 1400px;
}

.info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}

.info-title>span {
    position: relative;
}

.info-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: var(--info-title-before-background);
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.info-description {
    margin: 64px;
    text-align: center;
    color: var(--info-description-color);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}

/* Cards */
.info-cards-content {
    width: 100%;
    max-width: 1400px;
    padding: 20px;
    margin: 64px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    align-items: center;
    justify-items: center;
}

.info-cards {
    height: auto;
    min-width: 250px;
    max-width: 400px;
    border: 1px solid var(--info-cards-border);
    border-radius: 12px;
    background-color: var(--info-cards-background);
    position: relative;
    box-shadow: 0 4px 8px var(--info-cards-box-shadow-color), 0 6px 20px var(--info-cards-box-shadow-color);
}

.info-fa-icon {
    margin: 40px 0px 20px 0px;
    color: var(--info-icon-color);
    font-size: 50px;
}

.info-card-title {
    color: var(--info-card-title-color);
    font-family: 'Rubik', sans-serif;
    font-size: 26px;
    letter-spacing: .8px;
    line-height: 1.5rem;
}

.info-card-description {
    margin: 36px 25px 36px 25px;
    color: var(--info-card-description-color);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5rem;
}

/* Responsive */
@media screen and (max-width: 1200px) {
    .info-description {
        margin: 64px 32px;
    }

    .info-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .info-description {
        margin: 64px 8px;
    }

    .info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}