.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, var(--footer-background-left), var(--footer-background-right));
    color: var(--footer-color);
    bottom: 0;
}

.footer-content {
    text-align: left;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-logo {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.footer-logo-image {
    max-height: 50px;
    max-width: 50px;
    content: var(--footer-logo-image);
}

:root {
    --footer-logo-image: url('../../public/logo192.png');
}

@media (prefers-color-scheme: dark) {
    :root {
        --footer-logo-image: url('../../public/logo192dark.png');
    }
}

.footer-links {
    font-size: 18px;
    padding-bottom: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--footer-overlay);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--footer-overlay-content-background);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px var(--footer-overlay-content-box-shadow-color);
    max-width: 800px;
    width: 90%;
    max-height: 95%;
    animation: slideIn 0.25s ease-out;
}

.overlay-header {
    position: sticky;
    top: 0;
    background-color: var(--footer-overlay-header-background);
    z-index: 10;
    padding-bottom: 20px;
}

.overlay-scrollable-content {
    overflow-y: auto;
    max-height: calc(100% - 60px);
}

.file-content {
    text-align: left;
    justify-content: left;
}

.close-button-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 24px;
    color: var(--footer-overlay-close-button-color);
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button:hover {
    background-color: var(--footer-overlay-close-button-hover-background);
    color: var(--footer-overlay-close-button-hover-color);
}

@keyframes slideIn {
    from {
        transform: translateY(-12%);
    }

    to {
        transform: translateY(0);
    }
}